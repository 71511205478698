import React ,{ useEffect } from "react";

function MessageDisplay({ code, onBack }) {

  useEffect(() => {
    const callNotifyCheck = async () => {
      try {
        const response = await fetch("https://api.kope.cl/notify/check", {
          method: "GET", // Método HTTP
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }

        const data = await response.json(); // Si el servidor responde con JSON
        console.log("Respuesta de la API:", data);
      } catch (error) {
        console.error("Error al llamar a la API:", error);
      }
    };

    callNotifyCheck();
  }, []); // El array vacío asegura que se ejecuta una vez al montar el componente



  return (
    <div className="flex justify-center items-center min-h-screen bg-amber-200">
      <div className="bg-neutral-300 border-4 border-cyan-900 p-8 rounded-lg shadow-lg w-full max-w-4xl flex flex-col items-center">
        <p className="text-lg text-right text-white-600 mb-6 w-full font-bold">Martes 10 diciembre de 2024</p>

        <h3 className="text-3xl font-semibold text-center text-gray-900 mb-4">¡Feliz Cumpleaños Marianela!</h3>

        {/* Imagen antes del primer párrafo */}
        <img 
          src="/images/palmera.jpg"
          alt="Icono de felicitaciones"
          className="mb-2 rounded-lg" 
        />
                    
        <p className="text-lg text-gray-900 mb-2 py-8">
        Es una alegría poder saludarte hoy. Contigo he conocido a una persona de muchas cualidades geniales: muy inteligente, simpática y una mamá admirable. Espero que este cumpleaños te traiga momentos llenos de felicidad y amor en el paraiso en donde estás.
        </p>
        
        <p className="text-lg text-gray-900 mb-2">
        ¡Felicidades y que tengas un año maravilloso!
        </p>
    
        <p className="text-lg text-right text-blues-600 mb-2 w-full font-bold">
            <span className="text-blue-500 font-bold">|||||||||</span>
            <span className="text-white font-bold">   |||||||||</span>
            <span className="text-red-500 font-bold"> |||||||||</span>
        </p>
        <p className="text-lg text-right text-blues-600 mb-2 w-full font-bold">- kp -</p>
      </div>
    </div>
  );
}

export default MessageDisplay;
