import React, { useState, useEffect } from "react";

function CodeRequest({ onSubmit, error, correctCode }) {
  const [input, setInput] = useState("");
  const [isDisabled, setIsDisabled] = useState(false); // Habilitar entrada desde el principio
  const [countdown, setCountdown] = useState(10); // Contador de segundos
  const [firstAttempt, setFirstAttempt] = useState(true); // Para saber si es el primer intento
  const [isSuccess, setIsSuccess] = useState(false); // Para manejar el estado de éxito

  // Temporizador de 10 segundos con actualización cada segundo
  useEffect(() => {
    if (countdown > 0 && !firstAttempt) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1); // Reducir el contador cada segundo
      }, 1000);

      return () => clearInterval(timer); // Limpiar el temporizador al desmontar el componente
    } else {
      setIsDisabled(false); // Habilitar la entrada cuando el contador llegue a 0
    }
  }, [countdown, firstAttempt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      if (input === correctCode) { // Verificar si el código ingresado es correcto
        setIsSuccess(true); // Mostrar mensaje de felicitaciones
      } else {
        setIsSuccess(false); // Si el código es incorrecto, no mostrar mensaje de éxito
      }
      onSubmit(input); // Pasar el código al componente padre
      setInput(""); // Limpiar el campo después de enviar
      setFirstAttempt(false); // Marcar que no es el primer intento
      setIsDisabled(true); // Deshabilitar entrada mientras esperamos
      setCountdown(10); // Reiniciar el contador
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold text-blue-600 mb-6">¿ Código ?</h1>
      {error && <p className="text-red-600 mb-4">{error}</p>}
      
      {isSuccess ? (
        <div className="bg-green-100 text-green-800 p-4 rounded-lg shadow-md w-full max-w-sm text-center mb-6">
          <h2 className="text-xl font-semibold">¡ Felicidades !</h2>
          <p>El código es correcto.</p>
        </div>
      ) : isDisabled ? (
        <p className="text-lg text-gray-700">{countdown} segundos antes de volver a intentar...</p>
      ) : (
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Código aquí"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
            disabled={isDisabled} // Deshabilitar mientras `isDisabled` es true
          />
          <br />
          <button
            type="submit"
            className="w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-all disabled:bg-gray-400"
            disabled={isDisabled} // También deshabilitar el botón
          >
            Enviar
          </button>
        </form>
      )}
    </div>
  );
}

export default CodeRequest