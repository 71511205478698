import React, { useState } from "react";
import CodeRequest from "./components/CodeRequest";
import MessageDisplay from "./components/MessageDisplay";
import './index.css';  // O el nombre del archivo CSS que contiene las directivas de Tailwind


function App() {
  const [screen, setScreen] = useState("request"); // "request" o "display"
  const [code, setCode] = useState(""); // Código ingresado
  const [error, setError] = useState(""); // Mensaje de error

  // Código válido para la verificación
  const validCode = "0667";

  const handleCodeSubmit = (inputCode) => {
    if (inputCode === validCode) {
      setCode(inputCode);
      setScreen("display"); // Cambiar a la pantalla de mensaje
      setError(""); // Limpiar errores
    } else {
      setError("El código ingresado es incorrecto...");
    }
  };

  const handleBack = () => {
    setCode("");
    setScreen("request"); // Volver a la pantalla inicial
    setError(""); // Limpiar errores
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }} className="bg-amber-200">
      {screen === "request" ? (
        <CodeRequest onSubmit={handleCodeSubmit} error={error} />
      ) : (
        <MessageDisplay code={code} onBack={handleBack} />
      )}
    </div>
  );
}

export default App;
